export const bodyModel = {
    clientApi: {
        baseUrl: "",
        clientIntegration: false,
        system: {
            id: "",
            systemName: "",
            logo: "",
            description: "",
            systemFields: [
                {
                    key: "", 
                    value: "",
                    type: "",
                    placeHolder: ""
                }
            ]
        }
    },
    establishment: {
        tokenDonuz: "",
        establishmentId: ""
    },
    integrationType: "",
    local: false,
    lastUpdate: "",
    delay: "",
    cpfIsPassword: true,
    datetimeOffset: "",
    filialCode:"",
    pointRule:"",
    isRunning: true
}