export const formatter = {
  cpf: (input) => {
    return input
      .replace(/[^\d]/g, "")
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },

  cnpj: (input) => {
    return input
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
  },

  currency: (input) => {
    return input.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  },
};
