import System from "../../integrations/system/System.vue";

export default {
    data: () => ({
        systems: null
    }),
    components:{
        System
    },
    async created(){
        const resp = await this.DONUZ.System.GetAllSystems()
        this.systems = resp
    }
}