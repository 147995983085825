import { bodyModel } from "./FieldsForm";
import ModalDelete from './modalDelete/modalDelete.vue';
import Tooltip from './tooltip/Tooltip.vue';

export default ({
    props: [
        'systems',
        'integration'
    ],
    components: { ModalDelete, Tooltip },
    data: () => ({
        informationForm: {},
        systemsForm: {},
        register: false, //para verificar se é cadastro ou atualização
        validateForm: {},
        configFiliais: null,
        stopIntegration: false,
        rulePointEstablishment: null,
        dialog: false,
        regraName : '',
        // modal delete
        modalDelete: false,
        loading: false,
        // modal delete

    }),
    async created() {
        this.loading = true
        this.validateForm = {
            required: [v => !!v || 'Campo obrigatório'],
            delay: [v => (v && v >= 30) || 'Tempo mínimo de 30 minutos'],
        }            
        this.configurationFilial();
        await this.rulesEstablishment();

        this.regraName = 'Escolha sua regra de pontos';
        if (!this.systems) {
            this.informationForm = this.integration;
            const resp = await this.DONUZ.System.GetSystemById(this.informationForm.clientApi.system.id)
            resp.systemFields.forEach((element) => {
                element = this.informationForm.clientApi.system.systemFields.map((elementIntegration) => {
                    if (element.key == elementIntegration.key) {
                        element.value = elementIntegration.value
                       if(element.type == 'Date'){
                        element.value = element.value.substring(0,16)
                       }
                    }
                    return elementIntegration
                })
            })
            this.informationForm.clientApi.system = resp
            this.register = false;
            this.verifyLastUpdate();
            this.loading = false

            if(this.rulePointEstablishment.find(i => i.id_regra == this.informationForm.pointRule) != null){
                this.regraName = this.rulePointEstablishment.find(i => i.id_regra == this.informationForm.pointRule).nome_regra ?? '';
            }
            return;
        }
        this.loading = false
        this.initializeForm();

    },
    methods: {
        verifyLastUpdate() {
            let currentDate = new Date()
            currentDate.setMinutes(currentDate.getMinutes() - (this.informationForm.delay));

            if (currentDate.getTime() > new Date(this.informationForm.lastUpdate).getTime()) {
                this.stopIntegration = true
                return;
            }
            this.stopIntegration = false
        },
        initializeForm() {
            this.informationForm = JSON.parse(JSON.stringify(bodyModel));
            this.systemsForm = this.systems;

            //ultima atualização = data atual
            this.informationForm.lastUpdate = new Date().toISOString()

            //incluir no body as informações do sistema selecionado
            this.informationForm.clientApi.system = this.systems;

            //setando tipo da integração
            this.informationForm.integrationType = 'Api';

            //timezone de brasília
            this.informationForm.datetimeOffset = -3;
            this.informationForm.delay = 60;

            // pega estabelecimentoID e tokenDonuz
            this.informationForm.establishment.establishmentId = atob(sessionStorage.getItem('establishmentId'));
            var token = sessionStorage.getItem('tokenDonuz')

            if (token)
                this.informationForm.establishment.tokenDonuz = JSON.parse(Buffer.from(token, 'Base64'));

            this.register = true;
        },
        async configurationFilial() {
            var estabelecimento = atob(sessionStorage.getItem('establishmentId'));
            var result = JSON.parse(sessionStorage.getItem(`filiais-${estabelecimento}`));
            if (result == null) {
                const resp = await this.APPDONUZ.AppDonuzIntegration.GetIdFilial();
                if (resp.status == 200)
                    this.configFiliais = resp.filiais
                sessionStorage.setItem(`filiais-${estabelecimento}`, JSON.stringify(resp))
            }
            var filiais = [{id_filial : '' ,filial: 'Todas'} ,...result.filiais];
            this.configFiliais = filiais;
        },
        async rulesEstablishment() {
            var estabelecimento = atob(sessionStorage.getItem('establishmentId'));
            var result = JSON.parse(sessionStorage.getItem(`rules-${estabelecimento}`));
            if (result != null) {
                this.rulePointEstablishment = result.regras
                return; 
            }
            const resp = await this.APPDONUZ.AppDonuzIntegration.GetRulesEstablishment();
            if (resp.status != 200) return;
            this.rulePointEstablishment = resp.regras
            sessionStorage.setItem(`rules-${estabelecimento}`, JSON.stringify(resp))
        },
        toggle(property) {
            if (property == 'modalDelete')
                this.modalDelete = !this.modalDelete;

            if (property == 'loading')
                this.loading = !this.loading;

            if (property == 'dialog')
                this.dialog = !this.dialog;
        },

        async registerOrUpdateIntegration(integration) {
            if (!this.$refs.form.validate()) {
                this.$toasted.global.error({ msg: 'Preencha todos os campos' })
                return;
            }
            if (this.register) {
                this.registerIntegration(integration);
                return;
            }
            this.updatedIntegration(integration)
        },
        async registerIntegration(integration) {
            const resp = await this.DONUZ.Integration.PostIntegration(integration)
            if (resp.statusCode != 201) {
                this.$toasted.global.error({ msg: resp.erro })
                return;
            }
            this.$toasted.global.success();
            this.$router.push('/');
        },
        async updatedIntegration(integration) {
            // Atribuir systemFields do system dentro intregration
            const resp = await this.DONUZ.Integration.UpdateIntegration(integration);
            if (resp.statusCode != 200) {
                this.$toasted.global.error()
                return;
            }
            this.$toasted.global.success()
            this.$router.go({ name: 'Home' });
        },
        async deleteIntegration(id) {
            this.toggle('loading')
            const resp = await this.DONUZ.Integration.DeleteIntegration(id)
            if (resp.statusCode != 200) {
                this.$toasted.global.error()
            }
            else {
                this.$toasted.global.success()
                this.$router.go({ name: 'Home' })
            }
            this.toggle('loading')
            this.toggle('modalDelete')
        }
    }
})