import { get, post } from './MethodsApp';

export const appDonuz = {
  AppDonuzIntegration: {
    GetIdFilial: () => get('estabelishment/branchs'),
    GetRulesEstablishment: () => get('points/rules'),
    PostLoginUser: (body) => post('estabelishment/login', body),
    PostLoginToken: (body) => post('loginToken', body),
    PostLoginAdmin: (body) => post('crm/login', body),
    GetPrizes: () => get('prizes'),
    GetContractedResources: () => get('recurso/contratados'),
  }
};