import SelectMultiple from "../../../genericsComponents/selectMultiple/SelectMultiple.vue"
import CsvImport from "../../../genericsComponents/csvImport/CsvImport.vue"

export default ({
    components: { SelectMultiple, CsvImport },

    data: () => ({
        nfceConfig: {
            id: "",
            estabelecimentoId: null,
            nomeConfig: "",
            tipoPremiacao: "",
            tipoResgate: "",
            qualquerCnpj: false,
            cnpjsAutorizados: [],
            valorMinimo: 0,
            produtos: [],
            premios: [],
            habilitarResgateSemValidarnfce: false,
            descricaoPontosConterProdutos: true,
        },
        typesOfRescue: [
            {
                show: "Valor Total da Nota",
                value: "NotaFiscal",
            },
            {
                show: "Valor Mínimo da Nota",
                value: "ValorNota",
            },
            {
                show: "Código do Produto",
                value: "CodProduto",
                index: 0,
            },
            {
                show: "Nome do Produto",
                value: "NomeProduto",
                index: 1,
            }
        ],
        typesOfAward: [
            {
                show: "Pontos",
                value: "ponto"
            },
            {
                show: "Prêmios",
                value: "premio"
            },
        ],
        validateForm: {
            required: [v => !!v || 'Campo obrigatório'],
        },
        establishmentId: null,
        isUpdate: false,
        branches: [],
        isMinimumNoteValue: false,
        prizes: [],
    }),

    async created() {
        this.isUpdate = !!this.$route.params.id;
        this.getEstablishment();
        const requests = [this.getBranches(), this.getPrizes()];
        if (this.isUpdate)
            requests.push(this.getConfigById());
        await Promise.all(requests);
    },

    methods: {
        getEstablishment() {
            const establishmentId = atob(sessionStorage.getItem('establishmentId'));
            this.establishmentId = establishmentId
            this.nfceConfig.estabelecimentoId = establishmentId
        },

        async getBranches() {
            let response = JSON.parse(sessionStorage.getItem(`filiais-${this.establishmentId}`));
            if (response)
                return this.branches = this.formatBranches(response.filiais);
            response = await this.APPDONUZ.AppDonuzIntegration.GetIdFilial();
            if (response.status != 200) return
            this.branches = this.formatBranches(response.filiais)
            sessionStorage.setItem(`filiais-${this.establishmentId}`, JSON.stringify(response))
        },

        async getPrizes() {
            const response = await this.APPDONUZ.AppDonuzIntegration.GetPrizes();
            this.prizes = response.premios.map((prize) => ({
                ...prize,
                id_produto: prize.id_produto.toString(),
            }));
        },

        formatBranches(branches) {
            return branches.map((branch) => ({
                ...branch,
                cnpj: this.onlyNumbers(branch.cnpj)
            }))
        },

        onlyNumbers(text) {
            const pattern = /\D/g;
            return text?.replace(pattern, "");
        },

        async getConfigById() {
            const nfceConfigId = this.$route.params.id
            const response = await this.DONUZ.Nfce.GetConfigById(nfceConfigId);
            if (response.status != 200)
                return this.$toasted.global.error({ msg: 'Configuração não encontrada.' })
            this.nfceConfig = response.data;
        },

        async saveConfig() {
            if (!this.$refs.form.validate())
                return this.$toasted.global.error({ msg: 'Preencha todos os campos.' })

            this.prepareConfig();
            let response;
            if (this.isUpdate)
                response = await this.updateConfig()
            else
                response = await this.createConfig()

            if (![200, 201].includes(response.status))
                return this.$toasted.global.error({ msg: 'Erro ao salvar configuração.' })

            this.$toasted.global.success({ msg: 'Configuração salva com sucesso.' })
            this.goToList()
        },

        async createConfig() {
            return await this.DONUZ.Nfce.PostConfig(this.nfceConfig);
        },

        async updateConfig() {
            return await this.DONUZ.Nfce.PutConfig(this.nfceConfig);
        },

        goToList() {
            this.$router.push({
                path: `/nfc-e/list`,
            })
        },

        updateAuthorizedCnpj(cnpjList) {
            this.nfceConfig.cnpjsAutorizados = cnpjList;
        },

        updateSelectedPrizes(prizes) {
            this.nfceConfig.premios = prizes;
        },

        prepareConfig() {
            const selectedTypeOfRescue = this.typesOfRescue.find((type) => type.value == this.nfceConfig.tipoResgate);
            const selectedTypeOfAward = this.typesOfAward.find((type) => type.value == this.nfceConfig.tipoPremiacao);
            const selectedPrizes = this.nfceConfig.premios.length === 0 ? null : this.nfceConfig.premios;

            this.nfceConfig.produtos = this.nfceConfig.produtos.map((product) => ({
                infoProduto: product.infoProduto,
                qtdPontos: selectedTypeOfAward.value == "ponto" ? product.qtdPontos : null,
                tipoResgate: selectedTypeOfRescue.index,
                premios: selectedPrizes,
            }))
        },

        csvSelected(csv) {
            this.nfceConfig.produtos = csv.splice(1, csv.length - 1).map((line) => ({
                infoProduto: line["nomes"] || line["codigos"],
                qtdPontos: line["pontos"],
            }))
        },
    },

    watch: {
        "nfceConfig.tipoResgate": {
            handler(typesOfRescue) {
                this.isMinimumNoteValue = typesOfRescue == "ValorNota";
            },
            immediate: true
        },
    },
})