import System from './system/System.vue'
import FormIntegration from './formIntegration/FormIntegration.vue'
import LogsIntegration from "./logsIntegration/LogsIntegration.vue";
import Tooltip from './formIntegration/tooltip/Tooltip.vue';


export default {
  name: 'Integration',
  components: {
    System,
    FormIntegration,
    LogsIntegration,
    Tooltip
  },
  data() {
    return {
      integrations: null,
    };
  },
  async created() {
    await this.GetIntegrations()
  },
  methods: {
    async GetIntegrations() {
      try {
        const resp = await this.DONUZ.Integration.GetIntegrationByEstablishmentByType(atob(sessionStorage.establishmentId),'Api')
        if (resp.length > 0) {
          sessionStorage.Integrations = btoa(JSON.stringify(resp))
          this.integrations = resp;
          return;
        }

        this.integrations = null
        this.loading = false
      
      } catch (e) {
        this.loading = false
      }
    },
  },
}