import { get, post, put, del, patch } from './Methods';

export const donuz = {
  Integration: {
    GetAllIntegration: () => get(`integration`),
    GetIntegrationByEstablishment: (idEstablishment) => get(`integration/establishment/${idEstablishment}`),
    PostIntegration: (body) => post('Integration', body),
    UpdateIntegration: (body) => put(`Integration`, body),
    DeleteIntegration: (field) => del(`Integration/${field}`),
    GetIntegrationById: (id) => get(`integration/${id}`),
    GetIntegrationByEstablishmentByType: (idEstablishment, type) => get(`integration/establishment/${idEstablishment}/${type}`),
    GetViewResult: (body, queryUpper = true) => post(`integration/view-result?queryUpper=${queryUpper}`, body, false),
  },
  IntegrationField: {
    GetAllFields: () => get('IntegrationFields')
  },
  System: {
    GetAllSystems: () => get('system'),
    GetSystemById: (id) => get(`system/${id}`),
    PostSystem: (body) => post('system', body),
    PutSystem: (id, body) => put(`system/${id}`, body)
  },
  Logs: {
    GetLogsById: (id, date) => get(`Log/${id}/${date}`)
  },
  NFe: {
    GetByEstablishment: (establishmentId) => get(`nfe-configs/establishment/${establishmentId}`),
    GetById: (id) => get(`nfe-configs/${id}`),
    Create: (nfeConfig) => post(`nfe-configs`, nfeConfig),
    Update: (nfeConfig) => put(`nfe-configs`, nfeConfig, false),
    Delete: (id) => del(`nfe-configs/${id}`, null, false),
  },
  Actions: {
    GetNfeStatus: (skip = 0, limit = 30, cpf, createdFrom, createdTo) => {
      return get(`actions/nfe/status?skip=${skip}&limit=${limit}&cpf=${cpf ?? ""}&createdFrom=${createdFrom ?? ""}&createdTo=${createdTo ?? ""}`);
    },
    ResetNfe: (id) => patch(`actions/${id}/reset`),
    NfeScoreManually: (id, body) => post(`actions/nfe/${id}/score-manually`, body),
  },
  EstablishmentBalance: {
    GetByEstablishment: () => get(`establishment-balance`),
  },
  Nfce: {
    GetConfigs: () => get("configuracoes", undefined, "nfce"),
    GetConfigById: (id) => get(`configuracoes/${id}`, undefined, "nfce"),
    PostConfig: (body) => post("configuracoes", body, undefined, "nfce"),
    PutConfig: (body) => put("configuracoes", body, undefined, "nfce"),
    DelConfig: (id) => del(`configuracoes/${id}`, undefined, undefined, "nfce"),
  },
  InvalidNfces: {
    GetInvalidNfces: () => get(`invalid-nfces`, undefined, "nfce"),
    ResetInvalidNfce: (id) => put(`invalid-nfces/${id}/reset`, undefined, undefined, "nfce"),
  },
};