import ShowLogs from "./showLogs/ShowLogs.vue";

export default {
    props: {
        id: String
    },
    components: {
        ShowLogs
    },
    data: () => ({
        searchDate: null,
        logIntegration: [],
        dialog: false
    }),
    created() {
        this.searchDate = new Date().toLocaleDateString('en-CA');
        

    },
    methods: {
        async GetLogsEstablishmentId() {
            if (!this.searchDate) {
                this.$toasted.global.error({ msg: 'Informe uma data!' })
                return;
            }

            this.searchDate = this.searchDate.replace(/([T,:])\w+/g, '')

            const resp = await this.DONUZ.Logs.GetLogsById(this.id, this.searchDate)
            this.logIntegration = resp

            if (!this.logIntegration.length) {
                this.$toasted.global.error({ msg: 'Não há registro nesta data!' })
                return;
            }
        },        
        toggle(property) {
            if (property == 'dialog')
                this.dialog = !this.dialog;
        }

    }
}
