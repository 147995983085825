import NfeMoreOptions from "./nfe-more-options/NfeMoreOptions.vue";

export default {
    components: { NfeMoreOptions },

    data: () => ({
        establishmentId: null,
        skip: 0,
        limit: 10,
        nfes: [],
        totalNfesDisplayed: 0,
        nfeStatus: {
            Success: "Sucesso",
            Error: "Erro",
            Waiting: "Em processamento",
            ManuallyScored: "Pontuada manualmente",
        },
        cpf: "",
        createdFrom: null,
        createdTo: null,
        cpfRule: [cpf => /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf) || cpf == '' || 'Formato inválido'],
        cpfIsValid: false,
        establishmentBalance: null,
    }),

    async created() {
        this.establishmentId = atob(sessionStorage.getItem("establishmentId"));
        await this.getInitialNFes();
        await this.getBalance();
    },

    methods: {
        async getNFes() {
            let response;
            if (this.filterFieldsAreNullOrEmpty) {
                response = await this.DONUZ.Actions.GetNfeStatus(this.skip, this.limit);
            }
            else if (this.filterFieldsAreValid) {
                const [cpf, createdFrom, createdTo] = this.getPreparedFilterFields();
                response = await this.DONUZ.Actions.GetNfeStatus(this.skip, this.limit, cpf, createdFrom, createdTo);
            }
            else return;

            if (response.statusCode != 200 || !response.data)
                return;
            this.nfes = response.data;
            this.totalNfesDisplayed = response.data.length;
        },

        async getBalance() {
            const response = await this.DONUZ.EstablishmentBalance.GetByEstablishment();
            if (response.statusCode == 200 && response.data.nFeRequestBalance)
                this.establishmentBalance = response.data.nFeRequestBalance;
        },

        async getInitialNFes() {
            this.skip = 0;
            await this.getNFes();
        },

        async nextPage() {
            if (!this.hasNextPages()) return;
            this.skip += this.limit;
            await this.getNFes();
        },

        async previousPage() {
            if (!this.hasPreviousPages()) return;
            this.skip -= this.limit;
            await this.getNFes();
        },

        hasPreviousPages() {
            return this.skip > 0;
        },

        hasNextPages() {
            return this.totalNfesDisplayed >= this.limit;
        },

        formatDate(date) {
            return new Date(date).toLocaleString();
        },

        getStatus(status) {
            return this.nfeStatus[status];
        },

        formatCpf(cpf) {
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            this.$toasted.global.success({ msg: 'Chave de acesso copiada.' });
        },

        validateCpf(cpf) {
            return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf);
        },

        onlyNumbers(text) {
            const pattern = /\D/g;
            return text.replace(pattern, "");
        },

        prepareCreatedFrom(createdFrom) {
            const firstMomentOfTheDay = new Date(createdFrom).toISOString();
            return firstMomentOfTheDay;
        },

        prepareCreatedTo(createdTo) {
            createdTo = new Date(createdTo).getTime();
            const lastMillisecondOfTheDay = 24 * 60 * 60 * 1000 - 1;
            const lastMomentOfTheDay = new Date(createdTo + lastMillisecondOfTheDay).toISOString()
            return lastMomentOfTheDay;
        },

        getPreparedFilterFields() {
            const cpf = this.onlyNumbers(this.cpf);
            const createdFrom = !this.createdFrom ? null : this.prepareCreatedFrom(this.createdFrom);
            const createdTo = !this.createdTo ? null : this.prepareCreatedTo(this.createdTo);
            return [cpf, createdFrom, createdTo];
        },

        clearFilterFields() {
            this.cpf = "";
            this.createdFrom = null;
            this.createdTo = null;
        },
    },

    computed: {
        filterFieldsAreNullOrEmpty() {
            return !this.cpf && !this.createdFrom && !this.createdTo
        },

        filterFieldsAreValid() {
            const cpfOk = this.cpfIsValid || this.cpf == "";
            return cpfOk;
        },
    },

    watch: {
        cpf: {
            async handler(cpf) {
                this.cpfIsValid = this.validateCpf(this.cpf);
                if (cpf.length != 11) return;
                this.cpf = this.formatCpf(cpf);
            },
            immediate: true
        },
    },
};
