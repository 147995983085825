import store from '../store'
async function baseRequest(method, route, options, API_URL, HEADERS = null, responseAsJson = true) {
    let headers = {}
    !HEADERS ? headers =  {...store.state.headers } : headers = { ...HEADERS }

    if (!sessionStorage.getItem('establishmentId') != null){
        headers.estabelecimento = atob(sessionStorage.getItem('establishmentId'));
    }

    let body;
    if (options) {
        body = JSON.stringify(options)
    }

    const resp = await fetch(`${API_URL}${route}`, Object.assign({
        method,
        headers
    }, { body } || {}));

    if (!responseAsJson)
        return resp;

    const json = await resp.json();

    if (json.status != 200 && json.status != 201 && json.status)
        window?.VueContext?.$toasted?.global.error({ ...json, msg: json.mensagem });

    return json;
};

export default baseRequest;