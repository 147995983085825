import { formatter } from "../../../../../utils/Formatter.js";

export default {
  props: ["nfces"],

  methods: {
    getStatusDescription(status) {
      switch (status) {
        case "Error":
          return "Erro";

        case "WaitingToRun":
          return "Aguardando execução";

        case "Success":
          return "Sucesso";
      }
    },

    formatCnpj(input) {
      return formatter.cnpj(input);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toasted.global.success({ msg: 'Chave de acesso copiada.' });
    },
  },
};
