import { VueCsvImport } from "vue-csv-import";

export default {
    name: "CsvImport",

    components: {
        VueCsvImport,
    },

    props: {
        type: {
            type: String,
            validator: (value) => {
                return ["ProductCode", "ProductName", "ItemValue"].includes(value);
            }
        },
        products: Array,
    },

    data: () => ({
        mapFields: [],
        fileCSV: null,
        csvLink: null,
    }),

    created() {
        this.createCsv();
        this.mapFields = this.getMapFields();
    },

    methods: {
        getMapFields() {
            if (this.type === "ItemValue") return ["produtos"];
            return ["produtos", "pontos"];
        },

        createCsv() {
            const content = this.getCsvContent();
            const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
            this.csvLink = URL.createObjectURL(blob);
        },

        getCsvContent() {
            if (this.type === "ItemValue") {
                let content = "produtos";
                this.products.forEach(({ productInfo }) => content += `\n${productInfo}`);
                return content;
            }
            let content = "produtos,pontos";
            this.products.forEach(({ productInfo, points }) => content += `\n${productInfo},${points}`);
            return content;
        },
    },

    watch: {
        fileCSV: {
            handler: function (csv) {
                this.$emit("csv-selected", [...csv]);
            },
        },

        products: {
            handler: function (_) {
                this.createCsv();
            }
        }
    },
};
