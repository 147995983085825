<template>
  <v-app id="app" :class="{ viewRegister: $route.path == '/admin/cadastrar-sistema' }">
    <SideBar v-if="verifyLogin" />
    <router-view class="content" />
  </v-app>
</template>

<script>
import SideBar from "./template/components/sideBar/SideBar.vue";

export default {
  name: "App",
  components: { SideBar },
  data: () => ({
    verifyLogin: false,
  }),
  created() {
    if (sessionStorage.username || sessionStorage.remember_token)
      this.verifyLogin = true;
  },
  updated() {
    if (sessionStorage.username || sessionStorage.remember_token)
      this.verifyLogin = true;
    else this.verifyLogin = false;
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Segoe";
  src: url("./assets/fonts/segoe-ui.ttf");
}

.viewRegister {
  .content {
    padding: unset !important;
  }
}

#app {
  .primary--text {
    color: #473bf0 !important;
    caret-color: #473bf0 !important;
  }

  .v-application--wrap {
    overflow: hidden;
    flex-direction: unset !important;

    .content {
      overflow-y: scroll;
      height: 100vh;
      background-color: #f7f7f7;
    }
  }

  .toasted {
    border-radius: 10px !important;
  }
}

.toasted-container.top-right {
  top: 0% !important
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(175, 172, 172, 0.795);

  &:hover {
    background: rgba(124, 123, 123, 0.74);
  }
}
</style>