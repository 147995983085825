export default ({
    data: () => ({
        username: "",
        password: ""
    }),
    methods: {
        async postLogin() {
            sessionStorage.clear();
            const body = {
                "login": this.username, 
                "senha": this.password
            }
            const resp = await this.APPDONUZ.AppDonuzIntegration.PostLoginAdmin(body);
            this.validar(resp);            
        },
        validar(e) {
            if (e.status == 200) {
              sessionStorage.setItem('remember_token', btoa(JSON.stringify(e.remember_token)))
      
              this.$router.push('/');
            }
            else
              this.$toasted.global.error({ msg: 'Dados inválidos' })
          },
    }
})