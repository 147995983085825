export default ({
    name: 'Tooltip',
    props: ['descricao','icone'],
    data: ()=> ({
        iconeDefault: 'mdiAlertCircle'
    }),
    created(){
        this.icone ? this.iconeDefault = this.icone : ''
    }

})