import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    headers: {
      Token: "410d4633ae5741efb2adf25de2c1dbb0",
      'Content-Type': 'application/json'
    },
    contractedResources: null,
  },

  mutations: {
    setHeaders: (state, header) => { state.headers = header },
    setContractedResources: (state, contractedResources) => { state.contractedResources = contractedResources },
  },

  actions: {
    actionMountHeaders: async ({ commit }, header) => {
      commit('setHeaders', header)
    },
    setContractedResources: async ({ commit }, contractedResources) => {
      commit('setContractedResources', contractedResources);
    },
  },

  getters: {
    contractedResources: (state) => {
      return state.contractedResources;
    },
  }
})
