export default {
    name: 'SelectMultiple',

    props: {
        items: Array,
        itemText: String,
        itemValue: String,
        labelField: String,
        name: String,
        selectedItemsProp: Array,
    },

    data: () => ({
        selectedItems: [],
    }),

    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.allItems) {
                    this.selectedItems = [];
                    return;
                }

                this.selectedItems = []
                this.items.forEach((element) => {
                    this.selectedItems.push(element[this.itemValue])
                })
            })
        },
    },

    computed: {
        allItems() {
            return this.selectedItems.length === this.items.length
        },
        someItems() {
            return this.selectedItems.length > 0 && !this.allItems
        },
        icon() {
            if (this.allItems) return 'mdi-close-box'
            if (this.someItems) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    watch: {
        selectedItems: {
            handler: function (items) {
                this.$emit('items-updated', items)
            }
        },
        selectedItemsProp: {
            handler: function (items) {
                this.selectedItems = items;
            }
        }
    },
}