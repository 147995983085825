import { formatter } from "../../../../../utils/Formatter.js";

export default {
  props: ["nfce"],

  methods: {
    getStatusDescription(status) {
      switch (status) {
        case "Error":
          return "Erro";

        case "WaitingToRun":
          return "Aguardando execução";

        case "Success":
          return "Sucesso";
      }
    },

    async resetInvalidNfce() {
      const response = await this.DONUZ.InvalidNfces.ResetInvalidNfce(
        this.nfce.id
      );
      if (response.status != 200) return alert("Erro ao resetar NFC-e.");
      this.$emit("reset-invalid-nfce", this.nfce);
    },

    formatCpf(input) {
      return formatter.cpf(input);
    },

    formatCnpj(input) {
      return formatter.cnpj(input);
    },

    formatCurrency(input) {
      return formatter.currency(input);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toasted.global.success({ msg: 'Chave de acesso copiada.' });
    },
  },
};
