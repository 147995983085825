import { mapActions } from 'vuex';
export default ({
    data: () => ({
        username: "",
        password: ""
    }),
    methods: {
    ...mapActions(["actionMountHeaders"]),
    async postLogin() {
            sessionStorage.clear();
            const body = {
                "login": this.username, 
                "senha": this.password
            }
            const resp = await this.APPDONUZ.AppDonuzIntegration.PostLoginUser(body);
            this.validar(resp);
        },
        validar(e) {
            if (e.status == 200) {
                let header = {
                    Token: "410d4633ae5741efb2adf25de2c1dbb0",
                    'Content-Type': 'application/json',
                    estabelecimento: e.estabelecimento.id_admin
                  }
                  this.actionMountHeaders(header)
              sessionStorage.setItem('establishmentId', btoa(unescape(encodeURIComponent(JSON.stringify(e.estabelecimento.id_admin)))))
              sessionStorage.setItem('tokenDonuz', btoa(unescape(encodeURIComponent(JSON.stringify(e.token)))))
              sessionStorage.setItem('estabelecimento', e.estabelecimento.estabelecimento)
              sessionStorage.setItem('username', btoa(JSON.stringify(this.username)))
      
              this.$router.push('/');
            }
            else
              this.$toasted.global.error({ msg: 'Dados inválidos' })
          },
    }
})