import AllIntegrations from './allIntegrations/AllIntegrations.vue' 

export default {
  components: { AllIntegrations },
  data : () => ({
      allIntegrations: [],
      loading: true
  }),
  async created(){
    const resp = await this.DONUZ.Integration.GetAllIntegration();
    this.allIntegrations = resp;
    this.loading = !this.loading;
  }
};