export default{
    name: 'ModalDelete',
    props: {
        callback: Function,
        establishmentId: String,
        idToDelete: String,
    },
    data: () => ({
        modalDelete: true,
        enteredEstablishmentId: ''
    }),
    methods: {
        executarCallBack(){
            if(this.enteredEstablishmentId == this.establishmentId)
                this.callback(this.idToDelete);
            else
                this.$toasted.global.error({msg: 'EstabelecimentoID inválido'})
        }
    }
}