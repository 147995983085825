import Vue from 'vue';

// JS CONTEXT
window.DONUZ = require('../../src/integradorApi/Integrador').donuz;
window.APPDONUZ = require('../../src/appDonuzApi/AppDonuz').appDonuz;
window.ICON = require('../assets/js/Icons').icons

// VUE CONTEXT
Vue.prototype.DONUZ = window.DONUZ;
Vue.prototype.APPDONUZ = window.APPDONUZ
Vue.prototype.ICON = window.ICON;