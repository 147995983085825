export default {
    name: 'ScoreManually',

    props: {
        nfe: Object,
    },

    data: () => ({
        dialog: false,
        cpf: null,
        valueToPoint: null,
        cpfRule: [cpf => /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf) || cpf == '' || 'Formato inválido'],
    }),

    methods: {
        onlyNumbers(text) {
            const pattern = /\D/g;
            return text.replace(pattern, "");
        },

        async scoreManually() {
            if (!this.validatePoints(this.valueToPoint) || !this.validateCpf(this.cpf)) return;

            const response = await this.DONUZ.Actions.NfeScoreManually(this.nfe.id, {
                cpf: this.onlyNumbers(this.cpf),
                valueToPoint: this.valueToPoint
            });
            if (response.statusCode != 200)
                return this.$toasted.global.error({ msg: 'Erro ao pontuar manualmente a NF-e.' });

            this.nfe.status = "ManuallyScored";
            this.dialog = false;
            return this.$toasted.global.success({ msg: 'A NF-e foi pontuada manualmente.' });
        },

        validatePoints(points) {
            return Number(points) > 0;
        },

        validateCpf(cpf) {
            return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf);
        },

        formatCpf(cpf) {
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        },
    },

    watch: {
        cpf: {
            async handler(cpf) {
                this.cpfIsValid = this.validateCpf(cpf);
                if (cpf.length != 11) return;
                this.cpf = this.formatCpf(cpf);
            },
            immediate: true
        },
    },
}