import FormIntegration from "../formIntegration/FormIntegration.vue";

export default ({
    props: {
        isUpdate: Boolean,
        systems: Array,
        title: String
    },
    data: () => ({
        userAdmin: false,
    }),
    created(){
        sessionStorage.getItem('remember_token') ? this.userAdmin = true  : this.userAdmin = false
        if(this.userAdmin && this.$route.path == '/cadastrar'){
            this.$router.push({path: '/'})
            this.$toasted.global.error({msg: 'Você não possui permissão'})
        }
    },
    components: { FormIntegration },
    methods: {
        navigate(system) {
            sessionStorage.setItem('system',JSON.stringify(system));
            this.$router.push({
                path: 'editar-sistema',
            })
        }
    }
})