import {
    mdiViewGrid,
    mdiPlusBox,
    mdiChevronLeft,
    mdiEye,
    mdiEyeOff,
    mdiDeleteCircle,
    mdiExitToApp,
    mdiAccountBox,
    mdiStore,
    mdiApi,
    mdiClockTimeThree,
    mdiUpdate,
    mdiAlertCircle,
    mdiClose,
    mdiFileCodeOutline,
    mdiGreasePencil,
    mdiReload,
    mdiPowerSleep,
    mdiDatabase,
    mdiFileDocumentOutline,
    mdiTagTextOutline,
    mdiCheckCircleOutline,
    mdiCog,
    mdiFileChartOutline,
    mdiArrowTopRight,
    mdiCashRegister,
    mdiStar,
    mdiAlertCircleOutline,
    mdiAlertOctagonOutline,
    mdiDomain,
    mdiKey,
    mdiAccount,
    mdiFileDocument,
    mdiCogOutline,
    mdiArrowLeft,
    mdiCurrencyUsd,
    mdiCardAccountDetailsOutline,
    mdiViewList,
    mdiDotsVertical,
} from '@mdi/js'

export const icons = {
    mdiViewGrid,
    mdiPlusBox,
    mdiChevronLeft,
    mdiEye,
    mdiEyeOff,
    mdiDeleteCircle,
    mdiExitToApp,
    mdiAccountBox,
    mdiStore,
    mdiApi,
    mdiClockTimeThree,
    mdiUpdate,
    mdiAlertCircle,
    mdiClose,
    mdiFileCodeOutline,
    mdiGreasePencil,
    mdiReload,
    mdiPowerSleep,
    mdiDatabase,
    mdiFileDocumentOutline,
    mdiTagTextOutline,
    mdiCheckCircleOutline,
    mdiCog,
    mdiFileChartOutline,
    mdiArrowTopRight,
    mdiCashRegister,
    mdiStar,
    mdiAlertCircleOutline,
    mdiAlertOctagonOutline,
    mdiDomain,
    mdiKey,
    mdiAccount,
    mdiFileDocument,
    mdiCogOutline,
    mdiArrowLeft,
    mdiCurrencyUsd,
    mdiCardAccountDetailsOutline,
    mdiViewList,
    mdiDotsVertical,
}