import NfceList from "./list/NfceList.vue";
import NfceDetails from "./details/NfceDetails.vue";

export default {
  components: { NfceList, NfceDetails },

  data: () => ({
    loading: false,
    invalidNfces: [],
    selectedNfce: null,
    nfceDetails: false,
  }),

  async created() {
    this.loading = !this.loading;
    this.invalidNfces = await this.getInvalidNfces();
    this.loading = !this.loading;
  },

  methods: {
    async getInvalidNfces() {
      const response = await this.DONUZ.InvalidNfces.GetInvalidNfces();
      return response.data;
    },

    showNfceDetails(nfce) {
      this.selectedNfce = nfce;
      this.nfceDetails = true;
    },

    showNfceList() {
      this.selectedNfce = null;
      this.nfceDetails = false;
    },

    resetInvalidNfce(resetNfce) {
      const nfceIndex = this.invalidNfces.findIndex(
        (nfce) => nfce.id == resetNfce.id
      );
      if (nfceIndex == -1) return;
      this.invalidNfces[nfceIndex].status = "WaitingToRun";
    },
  },
};
