export const INTEGRATION_API_URL = 'https://integration.donuz.co/api/';
export const INTEGRATION_HEADERS = {
  masterToken: "410d4633ae5741efb2adf25de2c1dbb0",
  'Content-Type': 'application/json',
  login: '',
  password: ''
};

export const NFCE_API_URL = 'https://integration.donuz.co/nfce/api/';
export const NFCE_HEADERS = {
  estabelecimentoId: atob(sessionStorage.getItem('establishmentId')),
  token: "410d4633ae5741efb2adf25de2c1dbb0",
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': true // Retirar quando for pra produção
};
