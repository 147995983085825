import { INTEGRATION_API_URL, INTEGRATION_HEADERS, NFCE_API_URL, NFCE_HEADERS } from './Constants'
import baseRequest from "../base_api/api_methods";

export const get = (route, options, api = "integration") => baseRequest('GET', route, options,
    api == "nfce" ? NFCE_API_URL : INTEGRATION_API_URL,
    api == "nfce" ? NFCE_HEADERS : INTEGRATION_HEADERS);

export const post = (route, options, json, api = "integration") => baseRequest('POST', route, options,
    api == "nfce" ? NFCE_API_URL : INTEGRATION_API_URL,
    api == "nfce" ? NFCE_HEADERS : INTEGRATION_HEADERS, json);

export const put = (route, options, json, api = "integration") => baseRequest('PUT', route, options,
    api == "nfce" ? NFCE_API_URL : INTEGRATION_API_URL,
    api == "nfce" ? NFCE_HEADERS : INTEGRATION_HEADERS, json);

export const patch = (route, options, json, api = "integration") => baseRequest('PATCH', route, options,
    api == "nfce" ? NFCE_API_URL : INTEGRATION_API_URL,
    api == "nfce" ? NFCE_HEADERS : INTEGRATION_HEADERS, json);

export const del = (route, options, json, api = "integration") => baseRequest('DELETE', route, options,
    api == "nfce" ? NFCE_API_URL : INTEGRATION_API_URL,
    api == "nfce" ? NFCE_HEADERS : INTEGRATION_HEADERS, json);