import { donuz } from "../../../integradorApi/Integrador"
import Tooltip from '../integrations/formIntegration/tooltip/Tooltip.vue';
import LogsIntegration from "../integrations/logsIntegration/LogsIntegration.vue";
import ModalDelete from '../integrations/formIntegration/modalDelete/modalDelete.vue';

export default ({
    props: {
    },
    components: {
        Tooltip,
        LogsIntegration,
        ModalDelete
    },
    data: () => ({
        DatabaseIntegrations:[],
        stopIntegration: false,
        modalDelete: false,
    }),
    created(){
        this.getAllIntegrationByType();
       
    },
    methods: {
        async getAllIntegrationByType(){
            this.DatabaseIntegrations = 
            await donuz.Integration.GetIntegrationByEstablishmentByType(
                atob(sessionStorage.establishmentId),'Database')
        },
        download(content, fileName, contentType) {
            const a = document.createElement("a");
            const file = new Blob([content], { type: contentType });
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        },
  
        onDownload(id){
              this.download(JSON.stringify({local:true,integrationId: id}), "config.json", "text/plain");
        },
        editIntegration(id){
            this.$router.push({
                path: `/database/form/${id}`,
            })
        },
        toggle() {            
            this.modalDelete = !this.modalDelete;
        },
        async deleteIntegration(id) {
            const resp = await this.DONUZ.Integration.DeleteIntegration(id)
            if (resp.statusCode != 200) {
                this.$toasted.global.error()
            }
            else {
                this.$toasted.global.success()
                this.$router.go({ name: 'Home' })
            }
            this.toggle()
        },
        formatLastupdate(stringDate){
            const date = new Date(stringDate);
            const unixDate = new Date(0);
            return date > unixDate ? date.toLocaleString("pt-br") : "-"
        }
    }
})