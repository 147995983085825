import SelectMultiple from "../../../genericsComponents/selectMultiple/SelectMultiple.vue"
import CsvImport from "../../../genericsComponents/csvImport/CsvImport.vue"

export default ({
    components: { SelectMultiple, CsvImport },

    data: () => ({
        nfeConfig: {
            id: "",
            establishmentId: null,
            branchId: null,
            name: "",
            token: "",
            nFeValidationMethod: "",
            authorizeAnyCnpj: false,
            authorizedCnpj: [],
            minimumNoteValue: 0,
            validateReceiver: true,
            initialIssueDate: null,
            products: [],
            createdAt: "",
            updatedAt: ""
        },
        validationMethods: [
            {
                show: "Valor Total da Nota",
                value: "TotalNoteValue"
            },
            {
                show: "Valor Mínimo da Nota",
                value: "MinimumNoteValue"
            },
            {
                show: "Código do Produto",
                value: "ProductCode"
            },
            {
                show: "Nome do Produto",
                value: "ProductName"
            },
            {
                show: "Valor do Item",
                value: "ItemValue"
            },
        ],
        validateForm: {
            required: [v => !!v || 'Campo obrigatório'],
        },
        establishmentId: null,
        isUpdate: false,
        branches: [],
        isMinimumNoteValue: false,
        noBranch: { filial: "-", id_filial: null }
    }),

    async created() {
        this.isUpdate = !!this.$route.params.id;
        this.getEstablishment()
        this.getToken()
        await this.getBranches();
        if (this.isUpdate)
            await this.getConfigById();
    },

    methods: {
        getEstablishment() {
            const establishmentId = atob(sessionStorage.getItem('establishmentId'));
            this.establishmentId = establishmentId
            this.nfeConfig.establishmentId = establishmentId
        },

        getToken() {
            const token = atob(sessionStorage.getItem('tokenDonuz'));
            this.nfeConfig.token = token.replaceAll("\"", "")
        },

        async getBranches() {
            let response = JSON.parse(sessionStorage.getItem(`filiais-${this.establishmentId}`));
            if (response)
                return this.branches = this.formatBranches(response.filiais);
            response = await this.APPDONUZ.AppDonuzIntegration.GetIdFilial();
            if (response.status != 200) return
            this.branches = this.formatBranches(response.filiais)
            sessionStorage.setItem(`filiais-${this.establishmentId}`, JSON.stringify(response))
        },

        formatBranches(branches) {
            return branches.map((branch) => ({
                ...branch,
                cnpj: this.onlyNumbers(branch.cnpj)
            }))
        },

        onlyNumbers(text) {
            const pattern = /\D/g;
            return text?.replace(pattern, "");
        },

        async getConfigById() {
            const nfeConfigId = this.$route.params.id
            const response = await this.DONUZ.NFe.GetById(nfeConfigId);
            if (response.erro)
                return this.$toasted.global.error({ msg: 'Configuração não encontrada.' })
            this.nfeConfig = { ...response, branchId: +response.branchId, initialIssueDate: response.initialIssueDate.split('T')[0] }
        },

        async saveConfig() {
            if (!this.$refs.form.validate())
                return this.$toasted.global.error({ msg: 'Preencha todos os campos.' });

            let response;
            if (this.isUpdate)
                response = await this.updateConfig();
            else
                response = await this.createConfig();

            if (response.erro)
                return this.$toasted.global.error({ msg: 'Erro ao salvar configuração.' });

            this.$toasted.global.success({ msg: 'Configuração salva com sucesso.' });
            this.goToList();
        },

        async createConfig() {
            return await this.DONUZ.NFe.Create(this.nfeConfig);
        },

        async updateConfig() {
            return await this.DONUZ.NFe.Update(this.nfeConfig);
        },

        goToList() {
            this.$router.push({
                path: `/nfe/list`,
            })
        },

        updateAuthorizedCnpj(cnpjList) {
            this.nfeConfig.authorizedCnpj = cnpjList;
        },

        csvSelected(csv) {
            this.nfeConfig.products = csv.splice(1, csv.length - 1).map((line) => ({
                productInfo: line["produtos"],
                points: line["pontos"],
            }))
        },

        getProductsColumnName(validationMethod) {
            switch (validationMethod) {
                case "ProductName":
                    return "Nomes";
                case "ItemValue":
                    return "Códigos/Nomes";
                case "ProductCode":
                default:
                    return "Códigos";
            }
        },
    },

    watch: {
        "nfeConfig.nFeValidationMethod": {
            handler(validationMethod) {
                this.isMinimumNoteValue = validationMethod == "MinimumNoteValue";
            },
            immediate: true
        },
    },
})