import Tooltip from '../../integrations/formIntegration/tooltip/Tooltip.vue';
import LogsIntegration from "../../integrations/logsIntegration/LogsIntegration.vue";
import ModalDelete from '../../integrations/formIntegration/modalDelete/modalDelete.vue';

export default ({
    components: {
        Tooltip,
        LogsIntegration,
        ModalDelete
    },

    data: () => ({
        nfceConfigs: [],
        modalDelete: false,
        idToDelete: null,
        establishmentId: null,
        typesOfRescue: [
            {
                show: "Valor Total da Nota",
                value: "NotaFiscal"
            },
            {
                show: "Valor Mínimo da Nota",
                value: "ValorNota"
            },
            {
                show: "Código do Produto",
                value: "CodProduto"
            },
            {
                show: "Nome do Produto",
                value: "NomeProduto"
            }
        ],
        typesOfAward: [
            {
                show: "Pontos",
                value: "ponto"
            },
            {
                show: "Prêmios",
                value: "premio"
            },
        ],
    }),

    async created() {
        await this.getConfigs();
    },

    methods: {
        async getConfigs() {
            const response = await this.DONUZ.Nfce.GetConfigs()
            if (response.status != 200)
                return this.$toasted.global.error({ msg: 'Configuração não encontrada.' })
            this.nfceConfigs = response.data
        },

        async editConfig(id) {
            this.$router.push({
                path: `/nfc-e/form/${id}`,
            })
        },

        async deleteConfig(id) {
            const response = await this.DONUZ.Nfce.DelConfig(id);
            if (response.status != 204)
                return this.$toasted.global.error({ msg: 'Erro ao deletar configuração.' })
            this.$toasted.global.success({ msg: 'Configuração deletada com sucesso.' })
            this.toggleModalDelete()
            this.$router.go({ name: 'NFCeList' })
        },

        callDeleteConfig(config){
            this.idToDelete = config.id;
            this.establishmentId = config.estabelecimentoId;
            this.toggleModalDelete();
        },

        toggleModalDelete() {
            this.modalDelete = !this.modalDelete;
        },

        getTypeOfRescue(input) {
            const typeOfRescue = this.typesOfRescue.find((item) => item.value == input)
            if (!typeOfRescue) return "-"
            return typeOfRescue.show;
        },

        getTypeOfAward(input) {
            const typeOfAward = this.typesOfAward.find((item) => item.value == input)
            if (!typeOfAward) return "-"
            return typeOfAward.show;
        }
    }
})