import System from '../../components/integrations/system/System.vue'

export default {
    components: {
        System
    },
    data(){
        return {
            systems: []
        }
    },
    async created(){
        this.systems = await this.DONUZ.System.GetAllSystems()
    }
}