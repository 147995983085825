export default {
    name: 'ShowLogs',

    props: {
        log: Object,
        erro: Boolean,
    },
    data: () => ({
        logDescription: true,       
    }),
}