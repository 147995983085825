import FormIntegration from '../../../components/integrations/formIntegration/FormIntegration.vue'

export default {
    components: { FormIntegration },
    data: () => ({
        allIntegrations: [],
    }),
    async created() {
        const resp = await this.DONUZ.Integration.GetAllIntegration();
        this.allIntegrations = resp;
    }
}
