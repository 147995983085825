import Tooltip from '../../integrations/formIntegration/tooltip/Tooltip.vue';
import LogsIntegration from "../../integrations/logsIntegration/LogsIntegration.vue";
import ModalDelete from '../../integrations/formIntegration/modalDelete/modalDelete.vue';

export default ({
    components: {
        Tooltip,
        LogsIntegration,
        ModalDelete
    },

    data: () => ({
        nfeConfigs: [],
        stopIntegration: false,
        modalDelete: false,
        idToDelete: null,
        establishmentId: null,
        validationMethods: [
            {
                show: "Valor Total da Nota",
                value: "TotalNoteValue"
            },
            {
                show: "Valor Mínimo da Nota",
                value: "MinimumNoteValue"
            },
            {
                show: "Código do Produto",
                value: "ProductCode"
            },
            {
                show: "Nome do Produto",
                value: "ProductName"
            },
            {
                show: "Valor do Item",
                value: "ItemValue"
            }
        ],
    }),

    async created() {
        await this.getConfigsByEstablishment();
    },

    methods: {
        async getConfigsByEstablishment() {
            const establishmentId = atob(sessionStorage.getItem('establishmentId'))
            const response = await this.DONUZ.NFe.GetByEstablishment(establishmentId);
            if (response.erro)
                return this.$toasted.global.error({ msg: 'Configuração não encontrada.' })
            this.nfeConfigs.push(response)
        },

        async editConfig(id) {
            this.$router.push({
                path: `/nfe/form/${id}`,
            })
        },

        async deleteConfig(id) {
            const response = await this.DONUZ.NFe.Delete(id);
            if (response.status != 204)
                return this.$toasted.global.error({ msg: 'Erro ao deletar configuração.' })
            this.$toasted.global.success({ msg: 'Configuração deletada com sucesso.' })
            this.toggleModalDelete()
            this.nfeConfigs = [];
        },

        callDeleteConfig(config) {
            this.idToDelete = config.id;
            this.establishmentId = config.establishmentId;
            this.toggleModalDelete();
        },

        toggleModalDelete() {
            this.modalDelete = !this.modalDelete;
        },

        getValidationMethod(input) {
            const validationMethod = this.validationMethods.find((item) => item.value == input)
            if (!validationMethod) return "-"
            return validationMethod.show;
        },
    }
})