import ConfigRegister from "./ConfigRegister/ConfigRegister.vue"
import ShowViewResult from "./ShowViewResult/ShowViewResult.vue"
import Success from "./Success/Success.vue"

export default ({
  components: { ConfigRegister, ShowViewResult, Success },

  data: () => ({
    currentStep: 1,
    integrationConfig: null,
  }),

  methods: {
    nextStep(integrationConfig = null) {
      this.integrationConfig = integrationConfig;
      this.currentStep++;
    },

    previousStep() {
      this.currentStep--;
    },
  }
})