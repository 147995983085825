import ScoreManually from "../score-manually/ScoreManually.vue";

export default {
    components: { ScoreManually },

    props: {
        nfe: Object
    },

    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            this.$toasted.global.success({ msg: 'Chave de acesso copiada.' });
        },

        async resetNfe() {
            const response = await this.DONUZ.Actions.ResetNfe(this.nfe.id);
            if (response.statusCode != 200)
                return this.$toasted.global.error({ msg: 'Erro ao reprocessar NF-e.' });
            this.nfe.status = "Waiting";
            return this.$toasted.global.success({ msg: 'A NF-e está sendo reprocessada.' });
        },
    },
}