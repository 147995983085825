import LoginUser from './loginUser/LoginUser.vue';
import LoginAdmin from './loginAdmin/LoginAdmin.vue';
import { mapActions } from 'vuex';

export default {
  components: { LoginUser, LoginAdmin },
  data: {
    loading: false,
  },
  async created() {
    if (this.$route.params.tokenLogin) {
      sessionStorage.clear();

      const body = {
        "tokenClient": this.$route.params.tokenLogin
      }
      const resp = await this.APPDONUZ.AppDonuzIntegration.PostLoginToken(body);
      const validated = this.validar(resp);
      if (validated) {
        await this.getContractedResources();
      }
      this.loading = !this.loading
    }
    this.loading = !this.loading
  },
  methods: {
    ...mapActions(["actionMountHeaders", "setContractedResources"]),

    validar(e) {
      if (e.status == 200) {
        sessionStorage.setItem('establishmentId', btoa(unescape(encodeURIComponent(JSON.stringify(e.establishmentId)))))
        sessionStorage.setItem('tokenDonuz', btoa(unescape(encodeURIComponent(JSON.stringify(e.tokenDonuz)))))
        sessionStorage.setItem('username', btoa(JSON.stringify(this.username)))

        this.$router.push('/');
        return true;
      }
      else {
        this.$toasted.global.error({ msg: 'Dados inválidos' })
        return false;
      }
    },

    async getContractedResources() {
      const response = await this.APPDONUZ.AppDonuzIntegration.GetContractedResources();
      if (response && response.recursos_contratados)
        this.setContractedResources(response.recursos_contratados);
    },
  }
};